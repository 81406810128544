import React, { Component } from "react"

class LSTest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      body: ""
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      let body = JSON.stringify(window.localStorage)
      this.setState({ body })
    }
  }

  render() {
    return <>{this.state.body}</>
  }
}

export default LSTest
